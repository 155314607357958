.tf-stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.tf-value-changed {
  border: 3px solid #f0506e;
}

input[type="checkbox"].tf-value-changed {
  border: 3px solid #f0506e;
}

.truffle-icon {
  margin: 0;
  border: none;
  border-radius: 0;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  padding: 0;
  background-color: rgba(126, 56, 56, 0);
  display: inline-block;
  fill: red;
  line-height: 0;
}

.tf-stretch {
  display: flex;
  align-content: stretch;
}

.tf-min-width-column {
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.tf-wide-column {
  flex-grow: 1;
}

.tf-box {
  display: inline-block;
}

.tf-circle {
  display: inline-block;
  margin: 8px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  font-size: 16px;
  color: rgb(0, 42, 179);
  text-align: center;
  background: lightblue;
}

.tf-debug {
  border: 5px dotted red;
}

.tf-width-100 {
  width: 100px;
}

.tf-dim {
  opacity: 0.5;
}

.tf-dim:hover {
  opacity: 1;
}

.tf-demo-todos-table table th {
  vertical-align: middle;
}

.tf-demo-todos-table table td {
  text-align: center;
}

.tf-demo-todos-options-window {
  width: 620px;
}

.tf-demo-todos-options-window label {
  display: inline-block;
  width: 100%;
}

.tf-demo-todos-options-window>div.tf-modal-window-content>span {
  color: #1e87f0;
}

.tf-demo-area-wrapper {
  margin: 12px 0;
  padding: 0;
  text-align: center;
}

.tf-demo-area {
  display: inline-block;
  padding: 14px;
  margin: 0 auto;
  background: lightyellow;
  text-align: left;
}



.tf-snack {
  position: fixed;
  opacity: 0.95;
  bottom: 18px;
  right: 18px;
  border-radius: 2px;
  border: 1px solid #f2f2f2;
  background: #333;
  font-size: 18px;
  color: #fcfcfc;
  min-width: 360px;
  min-height: 140px;
  padding: 0;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.tf-snack .tf-snack-label {
  position: absolute;
  left: 18px;
  top: 18px;
  /* color: #fff; */
  color: #444;
  font-size: 22px;
  font-weight: bold;
}

.tf-snack .tf-snack-text {
  font-size: 18px;
  position: absolute;
  left: 21px;
  top: 53px;
  /* color: #eee; */
  color: #666;
}

.tf-snack .tf-snack-x {
  cursor: pointer;
  position: absolute;
  /* right: 4px; */
  right: 10px;
  /* top: -9px; */
  top: -2px;
  font-size: 24px;
  color: #f0506e;
}