.tf-stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.tf-value-changed, input[type="checkbox"].tf-value-changed {
  border: 3px solid #f0506e;
}

.truffle-icon {
  font: inherit;
  color: inherit;
  text-transform: none;
  fill: red;
  background-color: #7e383800;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  display: inline-block;
  overflow: visible;
}

.tf-stretch {
  align-content: stretch;
  display: flex;
}

.tf-min-width-column {
  max-width: 200px;
  flex-direction: column;
  display: flex;
}

.tf-wide-column {
  flex-grow: 1;
}

.tf-box {
  display: inline-block;
}

.tf-circle {
  width: 80px;
  height: 80px;
  color: #002ab3;
  text-align: center;
  background: #add8e6;
  border-radius: 50%;
  margin: 8px;
  font-size: 16px;
  line-height: 80px;
  display: inline-block;
}

.tf-debug {
  border: 5px dotted red;
}

.tf-width-100 {
  width: 100px;
}

.tf-dim {
  opacity: .5;
}

.tf-dim:hover {
  opacity: 1;
}

.tf-demo-todos-table table th {
  vertical-align: middle;
}

.tf-demo-todos-table table td {
  text-align: center;
}

.tf-demo-todos-options-window {
  width: 620px;
}

.tf-demo-todos-options-window label {
  width: 100%;
  display: inline-block;
}

.tf-demo-todos-options-window > div.tf-modal-window-content > span {
  color: #1e87f0;
}

.tf-demo-area-wrapper {
  text-align: center;
  margin: 12px 0;
  padding: 0;
}

.tf-demo-area {
  text-align: left;
  background: #ffffe0;
  margin: 0 auto;
  padding: 14px;
  display: inline-block;
}

.tf-snack {
  opacity: .95;
  color: #fcfcfc;
  min-width: 360px;
  min-height: 140px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: #fff3;
  border: 1px solid #ffffff4d;
  border-radius: 8px;
  padding: 0;
  font-size: 18px;
  position: fixed;
  bottom: 18px;
  right: 18px;
  box-shadow: 0 4px 30px #0000001a;
}

.tf-snack .tf-snack-label {
  color: #444;
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  top: 18px;
  left: 18px;
}

.tf-snack .tf-snack-text {
  color: #666;
  font-size: 18px;
  position: absolute;
  top: 53px;
  left: 21px;
}

.tf-snack .tf-snack-x {
  cursor: pointer;
  color: #f0506e;
  font-size: 24px;
  position: absolute;
  top: -2px;
  right: 10px;
}

/*# sourceMappingURL=index.ccc25563.css.map */
